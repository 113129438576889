import { Link } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="w-full text-center ">
        <h2 className="text-2xl font-bold inline-block my-8 p-3">404 </h2>
        <p>
          <Link to="/">&larr;Hem</Link>
        </p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
